.main {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    height: 100%;
    margin-top: 60px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 50px;
    text-align: center;
    font-weight: bold;
    color: cadetblue;
}

.subtitle {
    text-align: left;
    margin-top: -20px;
    font-weight: bold;
}

.section {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 15px;
}

.sub-title {
    font-size: 25px;
    font-weight: bold;
    text-align: left;
    color: cadetblue;
    margin-top: 20px;
    margin-bottom: 10px;
}

.text {
    text-align: left;
    margin-top: 0;
}

.screenshot-image {
    border: 3px solid cadetblue;
    border-radius: 10px;
    margin-top: 5px;
    cursor: pointer;
}

.lowfi-devices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 40px;
}

.lowfi-devices-left {
    width: 50%;
    text-align: left;
}

.lowfi-devices-right {
    width: 50%;
    text-align: right;
}

.lowfi-image-phone {
    border: 3px solid cadetblue;
    border-radius: 10px;
    width: 80%;
    text-align: left;
}
.lowfi-image-tablet {
    border: 3px solid cadetblue;
    border-radius: 10px;
    width: 94%;
}

.hifi-image-phone {
    border: 3px solid cadetblue;
    border-radius: 10px;
    width: 90%;
    text-align: left;
}
.hifi-image-tablet {
    border: 3px solid cadetblue;
    border-radius: 10px;
    width: 94%;
}

.section-title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
}

table {
    table-layout: fixed;
    border: 3px solid cadetblue;
    width: 100%;
    border-radius: 10px;
}

tr {
    vertical-align: top;
}

th {
    font-weight: bold;
    color: cadetblue;
    padding-top: 5px;
}

ul {

}

li {
    text-align: left;
    vertical-align: top;
    margin-bottom: 5px;
}

#link {
    color: cadetblue;
}